import React from 'react';
import './CardStyle.css';
import { SpinningCircles } from 'react-loading-icons'
import { useState ,useEffect} from 'react';

const Card = ({ image, name, price, priceSmall, priceLarge }) => {
   const [isLoading , setIsLoading] = useState(true);
    
   useEffect(() => {
    const img = new Image();
    img.src = require(`../../images/Items/${image}.jpg`);
    img.onload = () => setIsLoading(false);
    }, [image]);
    return (
        <div className="card">
           {isLoading ?
           <div className='spinning-circles'>
           <SpinningCircles/>
           </div> :
                <img 
                    src={require(`../../images/Items/${image}.jpg`)} 
                    alt={name} 
                    className="card-image" 
                    loading="lazy" 
                />}
            <div className="card-content">
                <h2 className="card-name">{name}</h2>
                {price !== 0 ? (
                    <p className="card-price">₪ {price}</p>
                ) : name === 'קוקיז' ? (
                    <>
                        <p className="card-price">קלאסי: ₪ {priceLarge}</p>
                        <p className="card-price">עם תוספות: ₪ {priceSmall}</p>
                    </>
                ) : (
                    <>
                        <p className="card-price">גדול: ₪ {priceLarge}</p>
                        <p className="card-price">קטן: ₪ {priceSmall}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default Card;
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './SwiperStyle.css';
import { useNavigate } from 'react-router-dom';
import hotDrink from '../../images/hot-coffee.jpg';
import coldDrink from '../../images/Items/1403.jpg';
import cookies from '../../images/cookies.jpg';

const categories = [
    { id: 'hot-drink', name: 'משקה חם', path: '/hot-drinks', image: hotDrink },
    { id: 'cold-drink', name: 'משקה קר', path: '/cold-drinks', image: coldDrink },
    { id: 'cookies', name: 'קוקיז', path: '/cookies', image: cookies },
];

export default function MySwiper() {
  const navigate = useNavigate();

  return (
    <Swiper
      effect="coverflow"
      spaceBetween={20}
      slidesPerView={2}
      centeredSlides={true}
      coverflowEffect={{
        rotate: 30,
        stretch: 10,
        depth: 150,
        modifier: 1,
        slideShadows: false,
      }}
      touchReleaseOnEdges={true}
      pagination={{ clickable: true }}
      modules={[EffectCoverflow, Pagination]}
      className="my-swiper"
    >
      {categories.map((category, index) => (
          <div key={category.id}  className="swiper-content">
                <SwiperSlide key={`${category.id}_${index}`}  onClick={() => navigate(category.path)}>
                    <img src={category.image} alt={category.name} />
                    <h3>{category.name}</h3>         
                </SwiperSlide>
        </div>
      ))}
    </Swiper>
  );
}

const hotDrinks = [
    { name: "הפוך", price: { large: 17, small: 12 }, code: "45" },
    { name: "נס קפה", price: { large: 17, small: 12 }, code: "786" },
    { name: "שוקו", price: { large: 17, small: 12 }, code: 860 },
    { name: "פרנץ וניל", price: { large: 17, small: 12 }, code: 887 },
    { name: "פרנץ אגוזים", price: { large: 17, small: 12 }, code: 871 },
    { name: "סחלב קלאסי", price: 18, code: 926 },
    { name: "סחלב קינדר", price: 21, code: 940 },
    { name: "סחלב פיסטוק", price: 21, code: 965 },
    { name: "סחלב לוטוס", price: 21, code: 1005 },
    { name: "תה", price: 10, code: 1015 },
    { name: "Hot chocolate", price:  25 , code: 1133 },
    { name: "שוקוליטה", price: { small: 18, large: 22 }, code: 1074 },
    { name: "אספרסו קצר", price: 10, code: 1309 },
    { name: "אספרסו ארוך", price: 10, code: 1309 },
    { name: "כפול קצר", price: 11, code: 1309 },
    { name: "כפול ארוך", price: 11, code: 1309 },
    { name: "אמריקנו", price: 12, code: 1319 },
    { name: "מאצה חם", price: { small: 18, large: 20 }, code: 1344 }
];

const coldDrinks = [
    { name: "מוחיטו בלו", price: 18 , code: "mojito blu" },
    { name: "מוחיטו תות", price: 18 , code: "mojito strawberry" },
    { name: "מילקשייק וניל", price: 26 , code: "vanilla" },
    { name: "מילקשייק פיסטוק", price: 26 , code: "pistachio" },
    { name: "מילקשייק קינדר", price: 26 , code: "kinder" },
    { name: "מילקשייק תריקס", price: 26 , code: "trix" },
    { name: "מאצה קר", price: 25, code: 1476 },
    { name: "קפה קר", price: 17, code: 1383 },
    { name: "אמריקאנו קר", price: 14 , code: 1356 },
    { name: "קפה קר בטעם קרמל", price: 20, code: 1403 },
    { name: "קפה קר בטעם שוקולד", price: 20, code: 1403 },
    { name: "קפה קר בטעם וניל", price: 20, code: 1403 },
    { name: "קפה קר בטעם אגוזים", price: 20, code: 1403 }
];

const cookies = [
    { name: "קוקי רגיל", code: 1273, price: 12 },
    { name: "קוקיז אוריאו", code: 1025, price:  16 },
    { name: "קוקיז", code: 1109, price:  16 } ,
    { name: "קוקיז פיסטוק", code: 1147, price: 16 } ,
    { name: " קוקיז קינדר", code: 1206, price:  16  },
];

export { hotDrinks, coldDrinks, cookies };
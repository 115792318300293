import React from 'react';
import './MenuPageStyle.css';
import { MdOutlineArrowBack } from "react-icons/md";
import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card';

const MenuPage = ({ name, menuItems }) => {
   
    return (
        <div className='menu-page'>
            <Link to="/" className="back-button"><MdOutlineArrowBack style={{ fontSize: '2rem' }} /></Link>
            <h1>{name}</h1>
            <div className="menu-items-container">
                <div className="menu-items" >
                    {menuItems.map((item, index) => (   
                        <Card 
                            key={index} 
                            image={item.code} 
                            name={item.name} 
                            priceSmall={item.price.small ?  item.price.small : item.price.classic}
                            priceLarge={item.price.large?  item.price.large : 0}
                            price={  typeof item.price === 'number' ? item.price : 0 } 
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MenuPage;